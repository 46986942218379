import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/johanneseslage/Desktop/Web/dev-website/src/components/Layout/Layout.js";
import Seo from "../components/seo";
import ProjectItem from "../components/ProjectItem/ProjectItem";
import ProjectList from "../components/ProjectList/ProjectList";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Homepage" mdxType="Seo" />
    <p>{`Hello, I am Johannes Eslage. I am a Frontend developer based in Hamburg, Germany. Currently I am working at `}<a parentName="p" {...{
        "href": "https://www.fork.de/"
      }}>{`Fork Unstable Media`}</a>{`.
You can find me on `}<a parentName="p" {...{
        "href": "https://github.com/jeslage",
        "title": "GitHub profile"
      }}>{`GitHub`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/~jeslage",
        "title": "NPM profile"
      }}>{`NPM`}</a>{` or contact me via `}<a parentName="p" {...{
        "href": "mailto:hello@johanneseslage.de"
      }}>{`mail`}</a>{`.`}</p>
    <ProjectList label="Latest websites" mdxType="ProjectList">
  <ProjectItem link="https://www.pelicanbar.de" image="pelicanbar.png" label="Pelican Bar Hamburg" color="#4abbb7" mdxType="ProjectItem" />
  <ProjectItem link="https://www.quasthaus.de" image="quasthaus.png" label="Quasthaus" color="#7f97a2" mdxType="ProjectItem" />
  <ProjectItem link="http://www.berghuis.de" image="berghuis.png" label="Berghuis Kälberhandel" color="#39be58" mdxType="ProjectItem" />
  <ProjectItem link="http://www.annaangold.de" image="anna-angold.png" label="Anna Angold" color="#e2fe74" mdxType="ProjectItem" />
  <ProjectItem link="http://www.pauleslage.de" image="paul-eslage.png" label="Paul Eslage" color="#ff7a5b" mdxType="ProjectItem" />
    </ProjectList>
    <ProjectList label="Little side Projects" mdxType="ProjectList">
  <ProjectItem link="https://beautiful-box-shadow.now.sh" image="beautiful-box-shadow.png" label="Beautiful Box Shadow" color="#aaa" mdxType="ProjectItem" />
      <ProjectItem link="https://brncjr.uber.space/" image="lorem-ipsum-generator.png" label="Last Lorem Ipsum" color="#2f2f2f" mdxType="ProjectItem" />
  <ProjectItem link="https://loremipsumgame.netlify.com" image="lorem-ipsum-game.png" label="Lorem Ipsum Game" color="red" mdxType="ProjectItem" />
  <ProjectItem link="https://shortcut-game.netlify.com" image="shortcut-game.png" label="Shortcut Game" color="#323232" mdxType="ProjectItem" />
    </ProjectList>
    <ProjectList label="NPM Packages" mdxType="ProjectList">
  <a href="https://github.com/jeslage/theme-playground" target="_blank">
    Theme Playground
  </a>
  <a href="https://github.com/jeslage/storybook-addon-theme-playground" target="_blank">
    Storybook Addon Theme Playground
  </a>
  <a href="https://github.com/jeslage/adaptive-modular-scale" target="_blank">
    Adaptive Modular Scale
  </a>
    </ProjectList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      